<template>
	<div class="container">
		<top-header></top-header>
		<div class="updateinfo width-1300" v-if="info">
			<div class="title">
				<span>编辑个人资料</span>
			</div>
			<div class="list">
				<div style="padding-top: 7px;">
					<img src="../../../../static/images/icon/user_icon1.png" alt="">
					<span>头像</span>
				</div>
				<el-upload class="upload-demo" :action="$store.state.httpUrl + 'front/reset/avatar'" :limit="1" name="avatar" :headers="headers"
				:on-change="imageFileEdit" ref="imageload" :show-file-list="false" accept=".jpg,.png,.jpeg,.gif" :on-success="headerSuccess">
					<img :src="$store.state.imaUrl + info.avatarUrl" alt="">
					<span class="iconfont iconyoujiantou"></span>
				</el-upload>
			</div>
			<div class="list">
				<div>
					<img src="../../../../static/images/icon/user_icon2.png" alt="">
					<span>公司名称</span>
				</div>
				<div>
					<span>{{info.company.name}}</span>
				</div>
			</div>
			<div class="list">
				<div>
					<img src="../../../../static/images/icon/user_icon3.png" alt="">
					<span>部门</span>
				</div>
				<div>
					<span>{{info.department}}</span>
				</div>
			</div>
			<div class="list">
				<div>
					<img src="../../../../static/images/icon/user_icon4.png" alt="">
					<span>手机号</span>
				</div>
				<div>
					<span>{{info.phone}}</span>
				</div>
			</div>
			<div class="list">
				<div>
					<img src="../../../../static/images/icon/user_icon5.png" alt="">
					<span>微信号</span>
				</div>
				<div>
					<span>{{info.wx_number ? info.wx_number : '暂无微信号'}}</span>
				</div>
			</div>
			<div class="bottom-btn">
				<span>保存</span>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import Cookie from 'js-cookie';
	import topHeader from '../../../components/header.vue'
	import bottomFooter from '../../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {
				headers:{
					'Front-Remember-Token':Cookie.get('frontRemembertoken')
				},
				info:''
			}
		},
		created() {
			this.getinfo();
		},
		methods: {
			getinfo() {
				this.$get('front/user/get_details', {}).then(res => {
					this.info = res.data.data;
				})
			},
			headerSuccess(){
				location.reload(true);
			},
			imageFileEdit(file, fileList) {
				if (fileList.length > 1) { //这里只获取最后一次选择的问津
					fileList.splice(0, 1);
				}
				if (fileList.length > 0) {
					let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1);
					let extension1 = fileName === 'png';
					let extension2 = fileName === 'jpg';
					let extension3 = fileName === 'jpeg';
					let extension4 = fileName === 'gif';
					if (!extension1 && !extension2 && !extension3 && !extension4) {
						this.$refs.imageload.clearFiles();
						this.$notify({
							type: 'error',
							title: '提示',
							message: '请上传正确格式的文件',
						});
						return false;
					} else {
						this.$refs.imageload.submit();
					}
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		background-color: #fff;
	}
	.updateinfo{
		margin-top: 60px;
		min-height: 585px;
		padding: 0 0;
		position: relative;
		padding-top: 30px;
		box-sizing: border-box;
		.title{
			padding: 0 15px;
			box-sizing: border-box;
			margin-bottom: 20px;
			font-size: 14px;
		}
		.bottom-btn{
			position: absolute;
			bottom: 20px;
			font-size: 14px;
			left: 0;
			right: 0;
			padding: 15px 0;
			margin:0 15px;
			border-radius: 5px;
			text-align: center;
			color: #fff;
			background-color: #00337D;
		}
		.list{
			margin-top: 10px;
			padding: 0px 15px;
			font-size: 14px;
			padding-bottom: 10px;
			border-bottom: 1px solid #b9b9b9;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #666;
			div{
				&:nth-child(1){
					img{
						width: 25px;
						height: 25px;
						border-radius: 0;
					}
				}
			}
			span{
				vertical-align: middle;
				margin-left: 10px;
			}
			input{
				border: 0;
				outline: none;
				color: #666;
				text-align: right;
			}
			.iconyoujiantou{
				font-size: 14px;
				margin-left: 10px;
				vertical-align: middle;
			}
			img{
				vertical-align: middle;
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
		}
		.upload-demo{
			img{
				width: 40px !important;
				height: 40px !important;
				border-radius: 50% !important;
			}
		}
	}
</style>
